




























































// Import vendors ----------------------------------------------------------------------------------
import { computed, defineComponent, watch, onBeforeMount } from '@vue/composition-api';
// Import utils ------------------------------------------------------------------------------------
import { useFormObserver, useFormFieldText } from '@/utils/forms.utils';
import { useAnalytics } from '@/utils/analytics.utils';
// Import plugins ----------------------------------------------------------------------------------
import { usePodocoreModule } from '@/plugins/podocore';
// Import components -------------------------------------------------------------------------------
import AuthWrapper from '@/components/auth/AuthWrapper.vue';
import FormFieldText from '@/components/forms/FormFieldText.vue';
import FormFieldPassword from '@/components/forms/FormFieldPassword.vue';
import FormFieldCheckbox from '@/components/forms/FormFieldCheckbox.vue';
// Import configurations ---------------------------------------------------------------------------
import { apiConfig } from '@/config/api.config';
import AuthSignUpCredentialsConsent from './AuthSignUpCredentialsConsent.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AuthSignUpCredentials',
  components: {
    AuthWrapper,
    FormFieldText,
    FormFieldPassword,
    FormFieldCheckbox,
    AuthSignUpCredentialsConsent
  },
  props: {
    signUpService: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    const { trackFailure } = useAnalytics();
    const observer = useFormObserver();

    const documentsRequest = usePodocoreModule('request').useRequest(
      `${apiConfig.default}/documents/latests`
    );

    onBeforeMount(() => {
      documentsRequest.request();
    });

    const username = useFormFieldText<string>({
      key: 'username',
      type: 'email',
      value: '',
      rules: {
        required: true,
        email: true
      }
    });

    const password = useFormFieldText<string>({
      key: 'password',
      type: 'password',
      value: '',
      rules: {
        required: true,
        password: true
      }
    });

    const consents = useFormFieldText<string[]>({
      key: 'consents',
      value: [],
      rules: {
        checkbox: true
      }
    });

    function submit() {
      properties.signUpService.send({
        type: 'SIGN_UP',
        data: {
          username: username.value.toLowerCase(),
          password: password.value,
          consents: consents.value
        }
      });
    }

    const isErrored = computed(() =>
      [{ signingUp: 'failure' }].some((element) => properties.signUpService.state.value.matches(element))
    );
    watch(isErrored, (value) => {
      if (value)
        trackFailure('User SignUpFailed', properties.signUpService.state.value.context.signingUpError);
    });

    const isDisabled = computed(
      () =>
        [{ signingUp: 'processing' }, 'challenged', 'success'].some((element) =>
          properties.signUpService.state.value.matches(element)
        ) || documentsRequest.isPending.value
    );

    const isLoading = computed(() =>
      [{ signingUp: 'processing' }].some((element) => properties.signUpService.state.value.matches(element))
    );

    return {
      // Form observer
      observer,
      // Form fields
      username,
      password,
      consents,
      // Values
      documentsRequest,
      // Actions
      submit,
      // Flags
      isErrored,
      isDisabled,
      isLoading
    };
  }
});
