








// Import vendors ----------------------------------------------------------------------------------
import { defineComponent, onBeforeUnmount } from '@vue/composition-api';
import { useService } from '@xstate/vue';
// Import components -------------------------------------------------------------------------------
import AuthSignUpCredentials from '@/components/auth/signUp/AuthSignUpCredentials.vue';
// import AuthSignUpSaasCheatcodes from './AuthSignUpSaasCheatcodes.vue';
// -------------------------------------------------------------------------------------------------

export default defineComponent({
  name: 'AuthSignUp',
  components: { AuthSignUpCredentials /* AuthSignUpSaasCheatcodes */ },
  props: {
    authService: {
      type: Object,
      required: true
    }
  },
  setup(properties) {
    // Extract "sign in" service from "auth" parent
    const signUpService = useService(properties.authService.service.value.children.get('signUp'));

    // Reset service when leaving
    onBeforeUnmount(() => {
      signUpService.send({
        type: 'RESET'
      });
    });

    return {
      // Services
      signUpService
    };
  }
});
